@import 'home';
@import 'about';
@import 'contact';
@import 'disclaimer';
@import 'notFound';
@import 'past';

// Default scss for all pages
.page-padding {
    padding-top: $header-height + 35px;
    padding-bottom: 40px;
    // padding-left: 5vw;
    // padding-right: 5vw;
}

.justify-center {
    // display: flex;
    justify-content: center;
}

.page-min-height {
    min-height: $min-page-height;
}

@media (max-width: 767px) {
    .content {
        width: 80vw !important;
        
        h1,
        h2,
        h3,
        p {
            width: 100%;
        }
    }
}
.not-found {
    background-image: url('js/../../public/spotlight-cutoff.png');
    background-size: 65%;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start !important;
    background-color: #111111;

    a,
    p,
    h1,
    h2,
    h3 {
        color: darken($light-color, 20%);
    }

    a {
        &:hover {
            color: lighten($light-color, 40%);
        }
    }
}

@media (max-width: 1200px) {
    .not-found {
        background-size: 97.5%;
    }
}

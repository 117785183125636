.disclaimer {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    
    p {
        line-height: 1.5em;
        width: $page-width;
    }
}  
.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 0;
    width: 100%;
    background-color: #00141b;
    padding: 50px 0;
    box-shadow: 0 50vh 0 50vh #00141b;

    .footer-row {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        .footer-stuff {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;

            .copyright {
                margin: 0;
            }

            .contact {
                .sep {
                    color: $light-color;
                }
            }
        }

        .socials-footer-stuff {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 20vw;

            .socials {
              display: flex;
              flex-direction: row;
              gap: 40px;
            }
        }
    }

    a {
        color: white !important;
    
        &:hover {
            color: white !important;
            filter: brightness(.8);
        }
    }
}


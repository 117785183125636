@import 'variables';
@import '../components/components';
@import '../pages/pages';

@font-face {
    font-family: 'Inter';
    src: url('js/../../public/Inter-VariableFont_slnt,wght.woff') format('woff');
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    background-color: lighten($kinetic-gray, 35%);
    font-family:  'Inter', 'Verdana', 'Century Gothic', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif, Helvetica, Arial, sans-serif !important;
    font-weight: lighter;
}

a {
    margin: 0px;
    transition: color 0.2s ease;
    color: $dark-color;
    text-decoration: none;

    &:hover {
        color: darken($rainbow-blue, 40%);
    }
}

h2 {
    font-size: 2rem;
    font-weight: lighter;
    color: darken($rainbow-blue, 10%);
    margin: 0;
    margin-bottom: 10px;
    line-height: 1.3em;
}

h3 {
    font-size: 1.5rem;
    font-weight: lighter;
    color: $dark-color;
    margin: 0;
    line-height: 1.3em;
}

p {
    font-size: 1.2rem;
    font-weight: lighter;
    color: $dark-color;
    margin: 0;
    line-height: 1.4em;
    margin-bottom: .75rem;
}

.fade-in {
    animation: fade-in-animation .5s ease-in-out;
}

@keyframes fade-in-animation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    position: fixed;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: transparent;
    border: 1px solid transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: $kinetic-gray;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: lighten($kinetic-gray, 10%);
}
.past {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .content {
        width: $page-width;

        h2,
        p {
            width: 100%;
        }
        
        a {
            color: $rainbow-blue;

            &:hover {
                color: darken($rainbow-blue, 20%);
            }
        }
    }  
}
.past-color-display-section {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    background-color: lighten($rainbow-blue, 40%);
    // opacity: 0.7;
    border-radius: 1rem;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: white;

    .past-color-card {
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: 1.5rem;
        background-color: $rainbow-blue;
        color: $white;
        // border-radius: .5rem;
        overflow: hidden;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease, box-shadow 0.2s ease;

        &:hover {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
            transform: scale(1.01);
        }
        
        .past-colors {
            display: flex;
            flex-direction: row;
            overflow-x: scroll;

            .past-color {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2rem;
                flex-grow: 1;

                .past-color-hex-code {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: .25rem;
                    background-color: $rainbow-blue;
                    color: $white;
                    padding: 3px 7px;
                    width: fit-content;
                    font-size: 0.75rem;
                }
            }
        }

        .past-color-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            padding-bottom: 1rem;
            text-align: center;

            .description {
                font-size: 1.25rem;
                font-weight: 500;
            }

            .reason {
                font-size: 1rem;
                font-weight: 400;
            }

            .dates {
                font-size: .9rem;
                font-weight: 400;
            }
        }
    }
}
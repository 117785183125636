.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    width: 100vw;
    background-color: $rainbow-blue;
    box-shadow: 0 0px 15px #0000007d;
    transition: opacity 0.3s ease, height 0.3s ease;
    z-index: 100;

    &:hover {
        opacity: 1 !important;
    }

    .logo {

       .logo-img {
            width: auto;
            height: 4em;
        }
    }

    .nav-bar {
        display: flex;
        flex-direction: row;
        gap: 20px;

        .nav-links {
            a,
            ScrollLink {
                font-size: 1em;
                color: $light-color;            
                
                &:hover {
                    color: darken($rainbow-blue, 40%);
                }
            }


        }
    }
}

@media (max-width: 1000px) {
    .header {
        .logo {
            .logo-img {
                height: 3.13em;
            }
        }
    }
}

@media (min-width: 768px) {
    .header {
        .nav-bar {
            .nav-links {
                a {
                    font-size: 1.2em;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .header {
        .logo {
            .logo-img {
                 width: auto;
                 height: 3em;
             }
         }

        .nav-bar {
            gap: 10px;
            .nav-links {
                a {
                    font-size: 1em;
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .header {
        .logo {
            .logo-img {
                 width: auto;
                 height: 2.5em;
             }
         }

        .nav-bar {
            gap: 10px;
            .nav-links {
                a {
                    font-size: .8em;
                }
            }
        }
    }
}
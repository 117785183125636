$dark-color: #3a3939;
$kinetic-gray: #9e9e9e;
$light-color: #f0f0f0;
$rainbow-red: #f35c5c;
$rainbow-orange: #f36849;
$rainbow-yellow: #ffb42a;
$rainbow-green: #99e550;
$rainbow-blue: #00ADEF;
$rainbow-purple: #a16ae8;
$rainbow-pink: #ff5991;
$white: #ffffff;

$darkened-rainbow-red: darken($rainbow-red, 55%);
$darkened-rainbow-orange: darken($rainbow-orange, 55%);
$darkened-rainbow-yellow: darken($rainbow-yellow, 55%);
$darkened-rainbow-green: darken($rainbow-green, 55%);
$darkened-rainbow-blue: darken($rainbow-blue, 55%);
$darkened-rainbow-purple: darken($rainbow-purple, 55%);
$darkened-rainbow-pink: darken($rainbow-pink, 55%);

$header-height: 110px;
$color-display-height: 500px;
$color-display-width: 100%;
$page-width: 60vw;
$min-page-height: 63.45vh;
.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    .content {
        display: flex;
        flex-direction: column;
        gap: 0px;

        .divider {
            height: 5.5em;
            
            hr {
                margin-top: 50px;
                margin-bottom: 50px;
            }
        }

        .color-section {
            width: $page-width;
        }

        .api-info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-self: center;
            width: $page-width;

            a {
                color: $rainbow-blue;

                &:hover {
                    color: darken($rainbow-blue, 20%);
                }
            }

            .code-content {
                color: darken($rainbow-blue, 40%);
                background-color: $light-color;
                padding: 10px;
                line-height: 1.5em;
                border-radius: 5px;
                border: 1px solid darken($light-color, 25%);
                white-space: pre-wrap;
            }
        }
    }
}

@media (max-width: 768px) {
    pre {
        font-size: 0.8em;
    }

    .api-info,
    .color-section {
        width: 80vw !important;
    }
}

@media (max-width: 450px) {
    pre {
        font-size: 0.8em;
    }

    .api-info,
    .color-section {
        width: 80vw !important;
    }
}
.cd-comp {
    .button-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 40px;

        .past-button {
            background-color: $rainbow-yellow;
            padding: 10px 15px;
            border-radius: 100px;
            transition: filter .3s ease, background-color .3s ease;
            color: $white !important;
            width: fit-content;

            &:hover {
                background-color: darken($rainbow-yellow, 10%);
                // transform: scale(1.025);
                // &::after {
                //     content: "👈";
                //     position: absolute;
                //     // margin-left: 10px;
                //     // top: 5px;
                //     // left: 5px;
                // }
            }

            &:active {
                transform: scale(0.98);
            }
        }
    }

    .color-section {
        background-color: $rainbow-blue;
        width: $color-display-width;
        margin-top: 15px;
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.2);
        height: fit-content;
        transition: 
            transform .3s ease, 
            box-shadow .3s ease;

        &:hover {
            transform: scale(1.01);
            top: 25vh;
            left: 25vw; 
            box-shadow: 0 0 15px hsla(0, 0%, 0%, 0.106);
        }
        
        .color-display {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: $color-display-height * calc(1/2);
            overflow-x: scroll;
            gap: 0;



            .color-display-card {
                display: grid;
                place-items: center; 
                flex: 1;
                height: 100%;

                h2 {
                    color: $white;
                    font-size: 1.2rem;
                    font-weight: thin;
                    padding: 2px 12px;
                    margin: 10px;
                    border-radius: 8px;
                    text-align: center;
                    line-height: 1.3em;
                    // -webkit-text-stroke: .5px rgb(34, 34, 34);
                    background-color: $rainbow-blue;
                
                }
            }
        }

        .color-information {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: fit-content;
            padding: 20px;
            min-height: 150px;

            .color-description {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                // margin: 20px;

                h2 {
                    color: $white;
                    font-size: 1.7rem;
                    text-align: center;
                }
                
                h3 {
                    color: $white;
                    font-size: 1.1rem;
                    text-align: center;
                }

                code {
                    color: $rainbow-red;
                    font-size: 1.2rem;
                    text-align: left;
                    word-wrap: break-word;
                    overflow-wrap: break-word;
                    background-color: lighten($dark-color, 10%);
                    width: 90%;
                    padding: 8px;
                    line-height: 1.6em;
                    border-radius: 5px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .color-section {
        width: 90vw;

        .color-display {
        
            .color-display-card {
                h2 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}


@media (max-width: 767px) {
    .color-section {

        h2 {
            font-size: 1rem !important;
        }

        h3 {
            font-size: 0.9rem !important;
        }    

        code {
            font-size: .7rem !important;
        }

        .display-hex-code {
            width: fit-content !important;
        }
    }
}

@media (max-width: 500px) {
    .color-section {

        a{
            font-size: 0.8rem !important;
        }

        h2 {
            font-size: 1rem !important;
        }

        h3 {
            font-size: 0.8rem !important;
        }    

        code {
            font-size: .7rem !important;
        }
    }
}
